import { IPopupItems } from "atic-common-helpers/helpers/interface.helper";
import { roles } from "features/theme/constant";

export const driver: IPopupItems[] = [
  {
    path: "profile_details",
    title: "Profile",
    icon: "profile",
  },
  {
    path: "driver/change-password",
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const admin: IPopupItems[] = [
  {
    path: "admin/change-password",
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const employee: IPopupItems[] = [
  {
    path: "employee/change-password",
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const profile: IPopupItems[] = [
  {
    path: `profile_details`,
    title: "Profile",
    icon: "profile",
    isProfile : true
  },
  {
    path: `user_badge_details`,
    title: "My Wallet",
    icon: "badge",
    isMyWallet: true
  },
  // {
  //   path: `user_badge_details`,
  //   title: "Badges",
  //   icon: "badge",
  // },
  // {
  //   title: "My Portal",
  //   icon: "portal",
  //   isProtal: true
  // },
  {
    path: `change_password`,
    title: "Reset Password",
    icon: "settings",
    isChangePassword: true
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
]

export const credentialing: IPopupItems[] = [
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
]

export const vehicleOwnerType = [
  { value: 'vehicle_owner', name: 'Vehicle Owner' },
  { value: 'registrant', name: 'Registrant' },
]
