import { roles } from "features/theme/constant";

const profile = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "verification",
      title: "Verification",
      type: "item",
      url: `${roles.profile}/completion/verification`,
      icon: "gridView",
      breadcrumbs: false,
      activeItem: [
        `/${roles.profile}/completion`,
        `/${roles.profile}/completion/verification`,
      ],
    },
  ],
};

// const kycNotVerified = {
//   id: "dashboard",
//   title: "Dashboard",
//   type: "group",
//   children: [
//     {
//       id: "Services",
//       title: "Services",
//       type: "item",
//       url: `${roles.profile}/services_item`,
//       icon: "gridView",
//       breadcrumbs: false,
//       activeItem: [
//         `/${roles.profile}/services`,
//         `/${roles.profile}/driver_create`,
//         `/${roles.profile}/driver_qr_verification`,
//       ],
//     },
//   ],

// }

const mobileUserProfile = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    // {
    //   id: "completion",
    //   title: "",
    //   type: "item",
    //   url: `/profile_details`,
    //   icon: "gridView",
    //   breadcrumbs: false,
    // },
  ],
};

const mobileUserProfileVerifed = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "credentialing",
      title: "Credentialing",
      appName:'Digital Credentialing',
      backServices: `/${roles.profile}/services_menu`,
      backServicesHref: false,
      parentId: "services",
      type: "item",
      url: `${roles.profile}/services`,
      icon: "gridView",
      breadcrumbs: false,
      activeItem: [
        `/${roles.profile}/services`,
        `/${roles.profile}/driver_create`,
        `/${roles.profile}/driver_qr_verification`,
      ],
    },
  ],
};

const mobileUserProfileNotVerifed = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "verification",
      title: "Verification",
      type: "item",
      url: `${roles.profile}/completion/mobile_user_verification`,
      icon: "gridView",
      breadcrumbs: false,
      activeItem: [`/${roles.profile}/completion/mobile_user_verification`],
    },
  ],
};

const credentialing = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [] as any,
};

export const credentialingChild = {
  id: "credentialing",
  title: "Credentialing",
  appName:'Digital Credentialing',
  backServices: `/${roles.profile}/services_menu`,
  backServicesHref: false,
  parentId: "services",
  type: "item",
  url: `${roles.profile}/services`,
  icon: "gridView",
  breadcrumbs: false,
  activeItem: [
    `/${roles.profile}/services`,
    `/${roles.profile}/agent_create`,
    `/${roles.profile}/agent_qr_verification`,
    `/${roles.profile}/driver_create`,
    `/${roles.profile}/driver_qr_verification`,
    `/${roles.profile}/fleet_operator_create`,
    `/${roles.profile}/fleet_qr_verification`,
    `/${roles.profile}/vehicle_owner_create`,
    `/${roles.profile}/vehicle_owner_qr_verification`,
    `/${roles.profile}/fleet_operator_other_create`,
    `/${roles.profile}/vehicle_owner_other_create`,
    `/${roles.profile}/service_provider_create`,
    `/${roles.profile}/service_provider_qr_verification`,
    `/${roles.profile}/service_provider_other_create`,
    `/${roles.profile}/vehicle_create`,
    `/${roles.profile}/dongle_create`,
    `/${roles.profile}/vehicle_permit_create`,
    `/${roles.profile}/others_profile_completion`
  ],
}

export const emptyToolBar = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: `default`,
      title: `Dashboard`,
      type: `item`,
      url: `/dashboard/coming_soon`,
      icon: `gridView`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/dashboard/coming_soon`]
    },
    {
      id: `services`,
      title: `Services`,
      type: `collapse`,
      url: `/${roles.profile}/services_menu`,
      isParentRoute: true,
      icon: `Dns`,
      breadcrumbs: false,
      activeItem: [
        `/${roles.profile}/services_menu`,
        `/${roles.profile}/services`,
        `/${roles.profile}/agent_create`,
        `/${roles.profile}/agent_qr_verification`,
        `/${roles.profile}/driver_create`,
        `/${roles.profile}/driver_qr_verification`,
        `/${roles.profile}/fleet_operator_create`,
        `/${roles.profile}/fleet_qr_verification`,
        `/${roles.profile}/vehicle_owner_create`,
        `/${roles.profile}/vehicle_owner_qr_verification`,
        `/${roles.profile}/fleet_operator_other_create`,
        `/${roles.profile}/vehicle_owner_other_create`,
        `/${roles.profile}/service_provider_create`,
        `/${roles.profile}/service_provider_qr_verification`,
        `/${roles.profile}/service_provider_other_create`,
        `/${roles.profile}/vehicle_create`,
        `/${roles.profile}/dongle_create`,
        `/${roles.profile}/vehicle_permit_create`,
        `/${roles.profile}/vehicle_edit`
      ]
    },
    {
      id: `member_portal`,
      title: `Member Portal`,
      type: `item`,
      url: `/member_portal/coming_soon`,
      icon: `userPerson`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/member_portal/coming_soon`]
    },
    {
      id: `my_business`,
      title: `My Business`,
      type: `item`,
      url: `/my_business/coming_soon`,
      icon: `AccountBalance`,
      breadcrumbs: false,
      href: false,
      activeItem: [`/my_business/coming_soon`]
    },
    {
      id: `reports`,
      title: `Reports`,
      type: `collapse`,
      url: `${process.env.REACT_APP_FHT_URL}login?url=/admin/Reports`,
      icon: `userReport`,
      breadcrumbs: false,
      href: true,
      children: [
        // {
        //   id: `reports-incidents`,
        //   title: `Incidents`,
        //   type: `item`,
        //   url: `${process.env.REACT_APP_IR_REDIRECTION_URL}login?url=/reports/admin/incidents`,
        //   icon: `ReportFile`,
        //   parentId: "reports",
        //   href: true,
        // },
        {
          id: "legal_services_reports",
          title: "Legal Services Reports",
          type: "item",
          url: `${process.env.REACT_APP_FHT_URL}login?url=/admin/Reports`,
          icon: "userReport",
          parentId: "reports",
          breadcrumbs: false,
          href: true,
        },
      ]
    },
    {
      id: `siteadministration`,
      title: `Site Admin`,
      type: `collapse`,
      url: `/${roles.admin}/siteadministration/adminusers`,
      // url: `/coming_soon_1`,
      icon: `ManageAccounts`,
      breadcrumbs: false,
      children: [
        {
          id: `adminusers`,
          title: `Admin Users`,
          type: `item`,
          url: `/${roles.admin}/siteadministration/adminusers`,
          icon: `PeopleAlt`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: true,
          activeItem: [`/${roles.admin}/siteadministration/adminusers`]
        },
        {
          id: `documenttemplate`,
          title: `Document Template`,
          type: `item`,
          url: `/coming_soon_2`,
          icon: `description`,
          breadcrumbs: false,
          parentId: "siteadministration",
          href: true,
          activeItem: [`/coming_soon_2`]
        },
        {
          id: 'location',
          title: 'Legal Services Master',
          type: 'item',
          url: `${process.env.REACT_APP_FHT_URL}login?url=/admin/master/service`,
          icon: 'location',
          href: true,
          parentId: 'master',
        },
        {
          id: `airdrops`,
          title: `Airdrops`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/airdrops_list`,
          icon: `Workspace`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `coupns`,
          title: `Coupons`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/coupons_list`,
          icon: `LocalOffer`,
          parentId: "siteadministration",
          href: true,
        }, {
          id: `tokens`,
          title: `Tokens`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/tokens_list`,
          icon: `TokenIcon`,
          parentId: "siteadministration",
          href: true,
        }, {
          id: `coupon_categories`,
          title: `Coupon Categories`,
          type: `item`,
          url: `${process.env.REACT_APP_DW_REDIRECTION_URL}login?url=/admin/coupon_categories`,
          icon: `CategoryIcon`,
          parentId: "siteadministration",
          href: true,
        },
        {
          id: `resources`,
          title: `Resources`,
          type: `item`,
          url: `${process.env.REACT_APP_IR_REDIRECTION_URL}login?url=/site_admin/admin/resources_list`,
          icon: `Settings`,
          parentId: "siteadministration",
          href: true,
        },
      ]
    },
  ],
};

const credentialingAdminAgent = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "credentialing",
      title: "Credentialing",
      parentId: "services",
      appName:'Digital Credentialing',
      backServices: `/${roles.profile}/services_menu`,
      backServicesHref: false,
      type: "item",
      url: `${roles.profile}/services`,
      icon: "gridView",
      breadcrumbs: false,
      activeItem: [
        `/${roles.profile}/services`,
        `/${roles.profile}/agent_create`,
        `/${roles.profile}/agent_qr_verification`,
        `/${roles.profile}/driver_create`,
        `/${roles.profile}/driver_qr_verification`,
        `/${roles.profile}/fleet_operator_create`,
        `/${roles.profile}/fleet_qr_verification`,
        `/${roles.profile}/vehicle_owner_create`,
        `/${roles.profile}/vehicle_owner_qr_verification`,
        `/${roles.profile}/fleet_operator_other_create`,
        `/${roles.profile}/vehicle_owner_other_create`,
        `/${roles.profile}/service_provider_create`,
        `/${roles.profile}/service_provider_qr_verification`,
        `/${roles.profile}/service_provider_other_create`,
        `/${roles.profile}/vehicle_create`,
        `/${roles.profile}/dongle_create`,
        `/${roles.profile}/vehicle_permit_create`,
        `/${roles.profile}/others_profile_completion`
      ],
    },
    {
      id: `service_agent_home`,
      title: `Service Representative`,
      type: `item`,
      url: `${roles.profile}/agent_house`,
      icon: `agent`,
      breadcrumbs: false,
      activeItem: [
        `/${roles.profile}/agent_list`,
        `/${roles.profile}/agent_house`,
      ],
    },
  ],
};

/* service providers */
export const servicesToolBar = {
  id: "credentialing",
  parentId: "services",
  title: "Credentialing",
  appName:'Digital Credentialing',
  backServices: `/${roles.profile}/services_menu`,
  backServicesHref: false,
  type: "item",
  url: `${roles.profile}/services`,
  icon: "gridView",
  breadcrumbs: false,
  activeItem: [
    `/${roles.profile}/services`,
    `/${roles.profile}/agent_create`,
    `/${roles.profile}/agent_qr_verification`,
    `/${roles.profile}/driver_create`,
    `/${roles.profile}/driver_qr_verification`,
    `/${roles.profile}/fleet_operator_create`,
    `/${roles.profile}/fleet_qr_verification`,
    `/${roles.profile}/vehicle_owner_create`,
    `/${roles.profile}/vehicle_owner_qr_verification`,
    `/${roles.profile}/fleet_operator_other_create`,
    `/${roles.profile}/vehicle_owner_other_create`,
    `/${roles.profile}/service_provider_create`,
    `/${roles.profile}/service_provider_qr_verification`,
    `/${roles.profile}/service_provider_other_create`,
    `/${roles.profile}/vehicle_create`,
    `/${roles.profile}/dongle_create`,
    `/${roles.profile}/vehicle_permit_create`,
    `/${roles.profile}/others_profile_completion`,
  ],
};

export const HousesToolBar = {
  id: "houses_toolbar",
  title: "My Business",
  type: "collapse",
  url: `${roles.profile}/services`,
  icon: "agent",
  breadcrumbs: false,
  activeItem: [],
  children: [] as any,
};

/* agent house */
export const agentHouseToolBar = {
  id: `service_agent_home`,
  title: `Service Representative`,
  type: `item`,
  url: `${roles.profile}/agent_house`,
  icon: `agent`,
  breadcrumbs: false,
  parentId: "houses_toolbar",
  activeItem: [`/${roles.profile}/agent_list`, `/${roles.profile}/agent_house`],
};

/* driver house */
export const driverHouseToolBar = {
  id: `driver_house`,
  title: `Driver`,
  type: `item`,
  url: `${roles.profile}/driver_house`,
  icon: `driver`,
  breadcrumbs: false,
  parentId: "houses_toolbar",
  activeItem: [`/${roles.profile}/driver_house`],
};

/* fleet operator house */
export const fleetOperatorHouseToolBar = {
  id: `fleet_house`,
  title: `Fleet`,
  type: `item`,
  url: `${roles.profile}/fleet_operator_house`,
  icon: `fleetOperator`,
  breadcrumbs: false,
  parentId: "houses_toolbar",
  activeItem: [`/${roles.profile}/fleet_operator_house`],
};

/* vehicle owner houser */
export const vehicleOwnerHouseToolBar = {
  id: `vehicle_owner_house`,
  title: `Vehicle Owner`,
  type: `item`,
  url: `${roles.profile}/vehicle_owner_house`,
  icon: `vehicleOwner`,
  breadcrumbs: false,
  parentId: "houses_toolbar",
  activeItem: [`/${roles.profile}/vehicle_owner_house`],
};

/* service provider houser */
export const serviceProviderHouseToolBar = {
  id: `service_provider_house`,
  title: `Service Provider`,
  type: `item`,
  url: `${roles.profile}/service_provider_house`,
  icon: `serviceProvider`,
  breadcrumbs: false,
  parentId: "houses_toolbar",
  activeItem: [`/${roles.profile}/service_provider_house`],
};

/* vehicle */
export const vehicle = {
  id: `vehicle`,
  title: `Vehicle`,
  type: `item`,
  url: `${roles.profile}/vehicle_list`,
  icon: `vehicle`,
  breadcrumbs: false,
  activeItem: [`/${roles.profile}/vehicle_list`, `/${roles.profile}/vehicle_edit`],
};

/* vehicle permit */
export const vehiclePermit = {
  id: `vehicle_permit`,
  title: `Vehicle Permit`,
  type: `item`,
  url: `${roles.profile}/vehicle_permit_list`,
  icon: `vehiclePermit`,
  breadcrumbs: false,
  activeItem: [`/${roles.profile}/vehicle_permit_list`],
};

/* dongle / obd */
export const dongle = {
  id: `dongle`,
  title: `Vehicle Telematics`,
  type: `item`,
  url: `${roles.profile}/dongle_list`,
  icon: `dongle`,
  breadcrumbs: false,
  activeItem: [`/${roles.profile}/dongle_list`],
};

export {
  profile,
  credentialing,
  credentialingAdminAgent,
  mobileUserProfile,
  mobileUserProfileVerifed,
  mobileUserProfileNotVerifed,
  // kycNotVerified
};
